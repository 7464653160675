import React from "react"
import Layout from "../components/Layout"
import {PageWrapper, PageContainer} from "../styles/GlobalStyles";
import ContactContent from "../constants/contacts";
import Seo from "../components/SEO";

const ContactsPage = () => {

    return (
        <Layout>
            <Seo title="Kontaktai - Bohabo Cornhole Lietuva"
                 description="Turite klausimų ar norite įsigyti Bohabo Cornhole žaidimą? Domina Cornhole žaidimo įrangos nuoma Jūsų šventei? Susisiekite su mumis!"
            />
            <PageWrapper>
                <PageContainer>
                    <ContactContent/>
                </PageContainer>
            </PageWrapper>
        </Layout>
    )
};

export default ContactsPage;